import styled, { useTheme } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { SliderDot, StyledSlider } from './Slider'
import { getOutlink } from '../../utils/analyticsUtils'
import useWindowSize from '../../hooks/useGatsbyWindowSize'

const TemplateCarousel = ({ hideDots }) => {
  const { width } = useWindowSize()
  const theme = useTheme()
  const templateWidth = 250
  // Calculate number of slides to show
  // eslint-disable-next-line no-bitwise
  const numberOfSlides = ~~(width / (templateWidth + 50)) // including padding
  // Image Styles
  const imageStyles = {
    borderRadius: '15px',
    boxShadow: theme.shadows.main,
    margin: '0 25px',
  }
  // ImageAlt if images are not displayed
  const templateAlt =
    'Semplates email template starters are a great way to get started developing hyper-personalized email templates for AWS SES quickly.'
  const hrefUrl = getOutlink()

  const sliderSettings = {
    customPaging() {
      return (
        <div>
          <SliderDot />
        </div>
      )
    },
    dots: !hideDots,
    dotsClass: 'slick-dots',
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    autoplay: true,
    slidesToShow: numberOfSlides,
    slidesToScroll: 1,
  }

  const SliderContainer = styled.div`
    width: 100vw;
    height: 500px;
  `

  const SlideContainer = styled(SliderContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
  `

  return (
    <SliderContainer>
      <StyledSlider {...sliderSettings}>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/01_seaside.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/02_mailing_list.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/03_festival.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/04_burger_menu.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/05_subscription.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/06_appointment.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/07_furniture.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
        <a href={hrefUrl} target="_blank" rel="noopener noreferrer">
          <SlideContainer>
            <StaticImage
              src="../../images/starters/08_eastern.png"
              alt={templateAlt}
              placeholder="none"
              layout="fixed"
              width={270}
              height={405}
              style={{ ...imageStyles, margin: 'auto' }}
            />
          </SlideContainer>
        </a>
      </StyledSlider>
    </SliderContainer>
  )
}

export default TemplateCarousel
