import React, { useState } from 'react';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { Cookies } from 'react-cookie/es6';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Link } from 'gatsby';
import { animated, config, useSpring } from 'react-spring';

const CookieContainer = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 60px;
  right: 16px;
  border-radius: 8px;
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.main};
  background-color: white;
  width: 80%;
  max-width: min(450px, calc(100vw - 80px));
  transition: all ease-in 0.25s;
  transition-delay: 0.25s;
  ${media.md`
    right: 32px;
    bottom: 32px;
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const BannerEmoji = styled.span`
  font-family: Exo;
  font-size: ${({ theme }) => theme.fontSizes.body1};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
`;

const BannerTitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-size: ${({ theme }) => theme.fontSizes.body1};
`;

const CookieText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body2};
  line-height: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  ${media.lg`
    justify-content: flex-start;
    flex-direction: row;
  `}
`;

const CookieButton = styled.button`
  padding: 12px 24px;
  text-align: start;
  background-color: ${({ theme, inactive }) =>
    inactive ? 'white' : theme.palette.secondary.main};
  border: ${({ theme }) => `1.5px solid ${theme.palette.secondary.main}`};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.light};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  font-size: ${({ theme }) => theme.fontSizes.body1};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: black;
  height: fit-content;
  transition: background-color 0.25s ease-in;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme, inactive }) =>
      inactive ? theme.palette.secondary.light : theme.palette.secondary.dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
  ${media.sm`
    padding: 8px 16px;
  `}
`;

const DataPrivacyLink = styled(StyledLink)`
  font-size: ${({ theme }) => theme.fontSizes.body2};
`;

const cookieTexts = {
  emoji: '🍪',
  title: 'Our cookie policy',
  text: 'We use cookie technology to analyse the website traffic and page usage, improve the navigation experience and support our marketing efforts.',
  linkText: 'Please read our Data Privacy Policy for more information.',
  acceptAllButton: 'Accept all',
  accptNecessaryButton: 'Only necessary',
};

const getExpirationDate = () => {
  const expireDate = new Date();
  expireDate.setTime(expireDate.getTime() + 365 * 24 * 60 * 60 * 1000);
  return expireDate;
};

const CookieBanner = ({ location }) => {
  const cookies = new Cookies();
  const ANALYTICS_CONSENT = 'semplates-analytics-consent';
  const cookieValue = cookies.get(ANALYTICS_CONSENT);
  const [open, setOpen] = useState(!cookieValue);

  const acceptAll = () => {
    cookies.set(ANALYTICS_CONSENT, true, { expires: getExpirationDate() });
    initializeAndTrack(location);
    setOpen(false);
  };

  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
    config: config.stiff,
  });

  if (open) {
    return (
      <animated.div style={props}>
        <CookieContainer>
          <BannerTitle>
            <BannerEmoji>{cookieTexts.emoji}</BannerEmoji>
            &nbsp;
            {cookieTexts.title}
          </BannerTitle>
          <CookieText>
            {cookieTexts.text}
            <br />
            <br />
            <DataPrivacyLink to="/data-privacy">{cookieTexts.linkText}</DataPrivacyLink>
          </CookieText>
          <ButtonRow>
            <CookieButton onClick={acceptAll}>
              {cookieTexts.acceptAllButton}
            </CookieButton>
            <CookieButton onClick={() => setOpen(false)} inactive>
              {cookieTexts.accptNecessaryButton}
            </CookieButton>
          </ButtonRow>
        </CookieContainer>
      </animated.div>
    );
  }
  return null;
};

export default CookieBanner;
