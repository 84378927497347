import axios from 'axios';

const getVersion = async queryParams => {
  try {
    const sourceDomain = document.referrer
    await axios.get(`${process.env.GATSBY_API_ENDPOINT}/version`,{
      headers: {
        'Content-Type': 'application/json',
        'source-domain': sourceDomain,
        'query-params': queryParams,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export default getVersion
