import { createGlobalStyle } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const GlobalStyle = createGlobalStyle`
  html {
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
  }

  body {
    color: #032733;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto
  }

  h1 {
    // hero heading
    font-family: Exo;
    font-weight: 900;
    margin: 0;
    font-size: 38px;
    line-height: 50px;
    ${media.md`
      font-size: 42px;
    `}
  }

  h2 {
    // section headings
    font-family: Exo;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    padding: 0;
    margin: 0;
    ${media.md`
      font-size: 36px;
    `}
  }

  h3 {
    font-family: Exo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    ${media.md`
      font-size: 18px;
    `}
  }

  h4 {
    // FeatureTitle
    font-family: Exo;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0.05em;
    text-align: left;
  }
  
  h5 {
    // TODO: Inconsistenly used --> Fix me for SEOw
    font-family: Exo;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.05em;
    text-align: left;
  }

  h6 {
    // text block section title
    font-family: Exo;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 0;
    letter-spacing: 0em;
    text-align: left;
    margin: 32px 0;
  }

  p {
    font-family: Exo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    ${media.md`
      font-size: 18px;
    `}
  }
  
  ul {
    font-family: Exo;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    padding-left: 32px;
  }

  .hiddenLgUp {
    display: block;

    ${media.lg`
      display: none;
    `}
  }

  .hiddenLgDown {
    display: none;

    ${media.lg`
      display: block;
    `}
  }

  .hiddenMdUp {
    display: block;

    ${media.md`
      display: none;
    `}
  }

  .hiddenMdDown {
    display: none;

    ${media.md`
      display: block;
    `}
  }
`

export const gridTheme = {
  breakpoints: {
    // defaults below
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 420,
  },
  row: {
    padding: 16, // default 15
  },
  col: {
    padding: 16, // default 15
  },
}

export const theme = {
  palette: {
    primary: {
      light: '#09759C',
      main: '#064861',
      dark: '#043647',
      gray: '#4E4E4E',
    },
    secondary: {
      light: '#F9E48A',
      main: '#FFD301',
      dark: '#E6BF00',
      gray: '#E8E8E8',
    },
    text: {
      primary: '#032733',
      secondary: '#FFFFFF',
      gray: '#4E4E4E',
    },
    background: {
      light: '#FFFCE9',
      main: '#FFFFFF',
      dark: '#064861',
      gray: '#F4F4F4',
    },
    success: {
      main: '#1DED4A',
    },
    warning: {
      light: '#E79FAC',
      main: '#E38190',
    },
    border: {
      gray: '#B8B7B7'
    },
    graphics: [
      '#084861',
      '#425D62',
      '#6F7560',
      '#9B9057',
      '#C8AF4B',
      '#F8D139',
    ],
  },
  borders: {
    radius: '6px',
    radiusLg: '24px',
  },
  shadows: {
    extralight: '0 10px 10px 0 rgba(0, 0, 0, 0.1)',
    light: '0 15px 15px 0 rgba(0, 0, 0, 0.1)',
    main: '0 20px 20px 0 rgba(0, 0, 0, 0.25)',
    editorHero: '0 5px 5px 5px rgba(0, 0, 0, 0.25)',
  },
  sizes: {
    headerHeight: '32px',
    headerLogoTopMargin: '4px',
    headerLogoWidth: '190px',
    footerHeight: '72px',
  },
  fontFamilies: {
    primary: 'Exo',
    secondary: 'Roboto',
  },
  fontSizes: {
    body1: '18px',
    body2: '16px',
    heading: '36px',
    subHeading: '28px',
    title: '42px',
  },
  fontWeights: {
    medium: '400',
    semiBold: '600',
    bold: '700',
    titleBold: '900',
  },
  ...gridTheme,
}
