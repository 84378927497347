import React from 'react'
import { loadableReady } from "@loadable/component"
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from './src/utils/theme'
import { CookieBanner } from './src/components/Shared'
import { loadVersion } from './src/utils/analyticsUtils'

export const onInitialClientRender = () => {
  var Tawk_API = Tawk_API || {};
    const Tawk_LoadStart = new Date()
  ;(function () {
    const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0]
    s1.async = true
    s1.src = 'https://embed.tawk.to/6007199aa9a34e36b96e1bca/1esdrode7'
    s1.charSet = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
  })()
}

export const onClientEntry = () => loadVersion()

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {element}
    <CookieBanner location={props.location} />
  </ThemeProvider>
)

export const replaceHydrateFunction = () => (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }