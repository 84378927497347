import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const SliderDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 20px;
  transition: background-color 0.25s ease-in;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.secondary.main : theme.palette.graphics[2]};
`

export const StyledSlider = styled(Slider)`
  & > .slick-dots {
    & > .slick-active {
      & ${SliderDot} {
        background-color: ${({ theme }) => theme.palette.secondary.main};
      }
    }
  }
`

export const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  width: 200px;
  height: 20px;
  & ${SliderDot} {
    margin: 5px;
  }
`

export const Dots = ({ active, length, centered }) => {
  const indeces = [...Array(length).keys()]
  return (
    <DotsContainer centered={centered}>
      {indeces.map(index => (
        <SliderDot isActive={active === index ? 1 : 0} key={index} />
      ))}
    </DotsContainer>
  )
}
