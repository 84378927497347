import getVersion from '../api/version'

// eslint-disable-next-line import/prefer-default-export
export const loadVersion = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search)
    // get all url params and write to string
    const urlParamsString = Array.from(urlParams).reduce(
      (acc, [key, value]) => `${acc}${key}=${value},`,
      ''
    )
    getVersion(urlParamsString)
  } catch (error) {
    console.log('Could not retrieve version')
  }
}

export const getOutlink = () => 'https://app.semplates.io/register'
