import React from 'react'
import { Facebook, Twitter, Linkedin } from 'react-feather'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import styled from 'styled-components'

const Container = styled.div`
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 0;
  & > button {
    color: ${({ theme }) => theme.palette.primary.main} !important;
    margin-right: 8px;
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.main} !important;
    }
  }
`

const ShareButtons = ({ url, title, description }) => (
  <Container>
    <FacebookShareButton url={url} quote={description}>
      <Facebook strokeWidth={1.25} />
    </FacebookShareButton>

    <LinkedinShareButton url={url} title={title} summary={description}>
      <Linkedin strokeWidth={1.25} />
    </LinkedinShareButton>

    <TwitterShareButton url={url} title={description}>
      <Twitter strokeWidth={1.25} />
    </TwitterShareButton>
  </Container>
)

export default ShareButtons
