import React, { useEffect, useRef, useState } from 'react'
import { useInViewRef } from 'rooks'
import { useSpring, animated, config } from 'react-spring'

export const FadeIn = ({ isVisible, children, delay }) => {
  const props = useSpring({
    opacity: isVisible ? 1 : 0.01,
    transform: isVisible ? 'translateY(0)' : 'translateY(40px)',
    delay: delay || 50,
    config: config.stiff,
  })
  return <animated.div style={props}>{children}</animated.div>
}

const FadeOnSlide = ({ children, delay }) => {
  const calledOnce = useRef(false)
  const [showChildren, setShowChildren] = useState(false)
  const [rootNode, isVisible] = useInViewRef()

  useEffect(() => {
    if (calledOnce.current) {
      return
    }

    if (isVisible) {
      setShowChildren(isVisible)
      calledOnce.current = true
    }
  }, [isVisible, setShowChildren])

  return (
    <div ref={rootNode}>
      <FadeIn isVisible={showChildren} delay={delay}>
        {children}
      </FadeIn>
    </div>
  )
}

export default FadeOnSlide
