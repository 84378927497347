import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { media } from 'styled-bootstrap-grid'

export const Label = styled.p`
  font-family: Exo;
  font-size: ${({ size }) => (size === 'sm' ? '16px' : '18px')};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  letter-spacing: 0.05em;
  text-align: left;
  margin: 8px;
  color: ${({ theme }) => theme.palette.text.primary};
`

const StyledActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${({ stacked }) => (stacked ? '16px 0' : '16px 24px')};
  padding: ${({ isSmall }) => (isSmall ? '4px 16px' : '8px 36px')};
  min-width: ${({ isSmall }) => (isSmall ? '80px' : '104px')};
  height: ${({ size }) => (size === 'sm' ? '32px' : '40px')};
  border-radius: 6px;
  text-decoration: none;
  background-color: ${({ theme, inactive }) =>
    inactive ? 'white' : theme.palette.secondary.main};
  border: ${({ theme, isSmall }) =>
    isSmall ? `1.5px solid ${theme.palette.secondary.main}` : 'none'};
  box-shadow: ${({ theme }) => theme.shadows.light};
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.25s ease-in;
  display: inline-block;
  &:hover {
    background-color: ${({ theme, inactive }) =>
      inactive ? theme.palette.secondary.light : theme.palette.secondary.main};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

const ExternalLinkButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: ${({ size }) => (size === 'sm' ? '4px 4px' : '8px 28px')};
  min-width: ${({ size }) => (size === 'sm' ? '80px' : '104px')}};
  height: 32px;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.25s ease-in;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

const InternalLinkButton = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  padding: ${({ size }) => (size === 'sm' ? '4px 4px' : '8px 36px')};
  min-width: 104px;
  height: 32px;
  border-radius: 6px;
  text-decoration: none;
  transition: background-color 0.25s ease-in;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

export const ActionButton = ({
  label,
  inactive,
  onClick,
  stacked,
  isSmall,
}) => (
  <StyledActionButton
    inactive={inactive ? 1 : 0}
    onClick={onClick}
    stacked={stacked}
    isSmall={isSmall}
  >
    <Label isSmall={isSmall}>{label}</Label>
  </StyledActionButton>
)

export const LinkButton = ({ label, to, size, props }) => {
  if (to.startsWith('http') || to.startsWith('mailto')) {
    return (
      <ExternalLinkButton
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        size={size}
        {...props}
      >
        <Label size={size}>{label}</Label>
      </ExternalLinkButton>
    )
  }
  return (
    <InternalLinkButton to={to} size={size} {...props}>
      <Label size={size}>{label}</Label>
    </InternalLinkButton>
  )
}

export const DiscoverButton = styled(Link)`
  height: fit-content;
  width: fit-content;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fontFamilies.main};
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  letter-spacing: 1.5px;
  color: ${({ color }) => color};
  border-color: ${({ color }) => color};
  border: 2px solid;
  border-radius: 8px;
  padding: 12px;
  text-decoration: none;
  transition: all 0.25s ease-in;
  ${media.md`
    margin-left: auto;
  `}
  &:hover {
    color: white;
    background-color: ${({ color }) => color};
    border-color: ${({ color }) => color};
  }
`

